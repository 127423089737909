@font-face {
  font-family: 'Yozal';
  src: url(../font/Yozai-Regular.ttf);
}
@font-face {
  font-family: 'Yozal';
  src: url(../font/Yozai-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: 'Yozal';
  src: url(../font/Yozai-Light.ttf);
  font-weight: lighter;
}
@font-face {
  font-family: 'Megrim';
  src: url(../font/Megrim.ttf);
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
button:focus {
  outline: 0;
}
.max-width-1280 {
  max-width: 1280px !important;
  margin: auto !important;
}
.max-width-600 {
  max-width: 600px !important;
  margin: auto !important;
}
.max-width-1280-no-margin {
  max-width: 1280px !important;
}
.body-color {
  background-color: #F9F7ED;
}
.min-wh-body {
  min-width: 100vw;
  min-height: 100vh;
}
.min-h-100 {
  min-height: 100vh;
}
.d-none {
  display: none;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.m-0px {
  margin: 0px !important;
}
.ml-5px {
  margin-left: 5px !important;
}
.ml-10px {
  margin-left: 10px !important;
}
.ml-15px {
  margin-left: 15px !important;
}
.ml-20px {
  margin-left: 20px !important;
}
.ml-30px {
  margin-left: 30px !important;
}
.mr-5px {
  margin-right: 5px !important;
}
.mr-10px {
  margin-right: 10px !important;
}
.mr-15px {
  margin-right: 15px !important;
}
.mr-20px {
  margin-right: 20px !important;
}
.mr-30px {
  margin-right: 30px !important;
}
.mt-5px {
  margin-top: 5px !important;
}
.mt-10px {
  margin-top: 10px !important;
}
.mt-15px {
  margin-top: 15px !important;
}
.mt-20px {
  margin-top: 20px !important;
}
.mt-30px {
  margin-top: 30px !important;
}
.mt-40px {
  margin-top: 40px !important;
}
.mb-5px {
  margin-bottom: 5px !important;
}
.mb-10px {
  margin-bottom: 10px !important;
}
.mb-15px {
  margin-bottom: 15px !important;
}
.mb-20px {
  margin-bottom: 20px !important;
}
.p-5px {
  padding: 5px !important;
}
.pl-0px {
  padding-left: 0px !important;
}
.pl-10px {
  padding-left: 10px !important;
}
.pl-15px {
  padding-left: 15px !important;
}
.pr-0px {
  padding-right: 0px !important;
}
.pr-10px {
  padding-right: 10px !important;
}
.pr-15px { 
  padding-right: 15px !important;
}
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.space-between {
  justify-content: space-between;
}

body {
  font-family: 'Yozal' !important;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch
}

.inner-body {
  width: 100vw;
  height: 100vh;
  min-height: 821px;
  overflow-y: scroll;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(110deg, #d9b2b7 43%, #b2646e 43%);
}

.box-div {
  min-width: 800px;
  max-width: 850px;
  min-height: 700px;
  max-height: 730px;
  width: 50vw;
  height: 80vh;
  background-color: #F9EDE7;
  border-radius: 30px;
  border: 5px solid #F37F7C;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-top: 80px;
  position: relative;
}
.box-header {
  width: 100%;
  height: 40px;
  background-color: #F37F7C;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.box-handle-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}
.box-handle-btn {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.btn-red {
  background-color: #EA4C48;
}
.btn-yellow {
  background-color: #F6C25E;
  margin-left: 15px;
}
.btn-green {
  background-color: #4CA752;
  margin-left: 15px;
}
.box-nav-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}
.box-nav-btn {
  font-size: 33px;
  color: #EA3A62;
}
.box-search-div {
  width: 68%;
  height: 26px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  z-index: 1;
}
.box-more-btn {
  font-size: 18px;
  color: #EA3A62;
}
.box-reload-div {
  font-size: 13px;
  color: #EA3A62;
}
.box-heart {
  position: absolute;
  left: 38.5%;
  top: -112px;
}

.box-body {
  padding: 70px 45px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.box-body-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.box-body-header-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.box-logo-a {
  width: 30%;
}
.box-logo {
  width: 100%;
}
.box-body-header-btn {
  width: 45%;
  max-width: 350px;
  margin-top: 8px;
}
.box-body-header-btn a {
  color: #666;
  position: relative;
}
.box-body-header-btn a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-image: linear-gradient(to right, #F37F7C 0%, #FF426C 100%);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}
.box-body-header-btn a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
.box-body-header-btn a:hover {
  text-decoration: none;
}
.box-body-header-btn .nav-btn {
  transition: 0.5s;
}
.box-body-header-btn .nav-btn:hover {
  transform: translateY(-3px);
}
.nav-sign-btn {
  background-color: #F37F7C;
  border-radius: 10px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
}
.nav-btn {
  font-weight: 600;
}
.nav-sign-btn:active {
  opacity: 0.6;
}

.main-div {
  color: #F37F7C;
}
.main-div-title {
  margin-top: 12%;
  font-size: 32px;
  -webkit-text-stroke-width: 1.2px;
  -webkit-text-stroke-color: #F37F7C;
}
.main-div-title b {
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #F37F7C;
}
.main-div-content {
  color: #333;
  margin-top: 7%;
  font-size: large;
  line-height: inherit;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #333;
}
.main-action-btn {
  background-size: 200% auto;
  background-image: linear-gradient(to right, #F37F7C 0%, #FF426C 51%, #F37F7C 100%);
  width: 45%;
  height: 50px;
  margin-top: 7%;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  cursor: pointer;
  transition: 0.5s;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: white;
  box-shadow: 0 0.5em 0.5em -0.4em #F37F7C;
}
.main-action-btn:hover {
  background-position: right center; /* change the direction of the change here */
  transform: translateY(-5px) !important;
}
.main-action-btn-disabled-hover:hover {
  background-position: left center; 
  transform: translateY(calc(-40px * calc(calc(var(--progress) - 0.1) * 3))) !important;
}
.main-action-btn:active {
  opacity: 0.6;
}
.main-scroll-down-div {
  position: absolute;
  bottom: 45px;
  right: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.main-scroll-down {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;
}

.lottie-overlay-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}
.lottie-bg {
  min-width: 1700px;
}
.progress-bar-div {
  width: calc(100vw * var(--progress));
  height: 5px;
  border-radius: 2px;
  background-color: #F37F7C;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.fade-out-1 {
  transform: translateY(calc(-20px * calc(var(--progress) * 3)));
  opacity: calc(1 - calc(var(--progress) * 2));
}
.fade-out-2 {
  transform: translateY(calc(-30px * calc(calc(var(--progress) - 0.05) * 3)));
  opacity: calc(1 - calc(calc(var(--progress) - 0.05) * 1.75));
}
.fade-out-3 {
  transform: translateY(calc(-40px * calc(calc(var(--progress) - 0.1) * 3)));
  opacity: calc(1 - calc(calc(var(--progress) - 0.1) * 1.25));
}
.fade-out-4 {
  opacity: calc(1 - var(--progress));
}
.fade-out-down-1 {
  transform: translateY(calc(20px * calc(calc(var(--progress)) * 3)));
  opacity: calc(1 - var(--progress));
}


/* ---------- Feature ------------- */
.feature-bg-outer-div {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature-bg-inner-div {
  margin-top: 80px;
  min-width: 1200px;
  max-width: 1350px;
  min-height: 680px;
  max-height: 730px;
  width: 80vw;
  height: 80vh;
  position: relative;
}
.feature-bg-left-row {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 15vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feature-bg-right-row {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 15vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feature-bg-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 30%;
  text-align: center;
}
.feature-bg-item:hover {
  text-decoration: none;
}
.feature-bg-item:active {
  opacity: 0.8;
}
.feature-bg-item-text {
  width: 60%;
  min-width: 150px;
  margin-top: 10px;
  color: #FBF1E2;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  font-weight: bold;
  -webkit-text-stroke-width: 0.8px;
  -webkit-text-stroke-color: #FBF1E2;
  letter-spacing: 0.3px;
}
.feature-bg-item:hover .feature-bg-item-img {
  transform: translateY(-3px);
}
.feature-bg-item:hover .feature-bg-item-text {
  -webkit-text-stroke-width: 1.1px;
  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}
.feature-bg-item-img {
  transition: 0.5s;
  width: 40%;
  min-width: 80px;
  max-width: 90px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.feature-outer-div {
  display: flex;
  margin-top: 40px;
}
.feature-left-div {
  min-width: 240px;
}
.feature-right-div {
  width: 100%;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.feature-right-div-title {
  font-size: 32px;
  color: #EA3A62;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #EA3A62;
}
.feature-phone-div {
  position: relative;
  min-width: 300px;
}
.feature-phone-img {
  min-height: 300px;
  max-height: 480px;
}
.feature-2-img-div {
  position: absolute;
  top: 19px;
  left: 3%;
  width: 100%;
}
.feature-2-img-main-div {
  position: absolute;
  top: 19px;
  left: 3%;
  width: 100%;
}
.feature-2-img-up {
  width: 70%;
}
.feature-2-img-down {
  margin-top: -2px;
  width: 70%;
}
.feature-2-content-outer {
  margin-top: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feature-2-content {
}
.feature-2-content-title {
  font-size: 24px;
  color: #F37F7C;
  -webkit-text-stroke-width: 1.2px;
  -webkit-text-stroke-color: #F37F7C;
}
.feature-2-content-content {
  color: #333;
  margin-top: 7%;
  font-size: large;
  line-height: inherit;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #333;
}
.feature-3-img-back {
  width: 16%;
  margin-left: 3%;
  margin-top: 25px;
}
.feature-3-img-content {
  width: 70%;
  margin-top: 3px;
}
.feature-3-img-content-margin {
  margin-top: 10px;
}
.feature-4-img-content {
  width: 71.5%;
  margin-top: 20px;
  margin-left: -2.5px;
}
.feature-5-img-content {
  width: 64%;
  margin-top: 20px;
  margin-left: 3%;
}
.feature-action-button-div {
  display: flex;
  flex-direction: column;
}
.feature-action-btn {
  background-size: 200% auto;
  background-image: linear-gradient(to right, #F37F7C 0%, #FF426C 51%, #F37F7C 100%);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  cursor: pointer;
  transition: 0.5s;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: white;
  margin-bottom: 10px;
}
.feature-action-btn:hover {
  background-position: right center; 
}
.feature-action-btn:active {
  opacity: 0.6;
}
.feature-demo-btn {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  color: #F37F7C;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #F37F7C;
  border: 2px solid #F37F7C;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  cursor: pointer;
  transition: 0.5s;
}
.feature-demo-btn:hover {
  background-color: #F37F7C;
  color: white;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: white;
}
.feature-demo-btn:active {
  opacity: 0.6;
}
.feature-right-icon-div {
  width: 80%;
  margin-right: -30px;
  min-width: 160px;
  filter: drop-shadow(0px 4px 1px rgba(0, 0, 0, 0.03));
}

.feature-phone-up-fade-out {
  --fade-value: var(--progress) - 0.4;
  --max-value: max(0, var(--fade-value));
  transform: translateY(calc(-20px * min(1, var(--max-value) * 10)));
  opacity: calc(1 - min(1, var(--max-value) * 10));
}
.feature-phone-down-fade-out {
  --fade-value: var(--progress) - 0.4;
  --max-value: max(0, var(--fade-value));
  transform: translateY(calc(20px * min(1, var(--max-value) * 10)));
  opacity: calc(1 - min(1, var(--max-value) * 10));
}
.feature-3-fade-out-2-up {
  --fade-value: var(--progress) - 0.9;
  --max-value: max(0, var(--fade-value));
  transform: translateY(calc(20px * min(1, var(--max-value) * 10)));
  opacity: calc(1 - min(1, var(--max-value) * 10));
}
.feature-3-fade-out-1 {
  --fade-value: var(--progress) - 0.4;
  --max-value: max(0, var(--fade-value));
  transform: translateY(calc(20px * min(1, var(--max-value) * 10)));
  opacity: calc(1 - min(1, var(--max-value) * 10));
}
.feature-3-fade-out-2 {
  --fade-value: var(--progress) - 0.9;
  --max-value: max(0, var(--fade-value));
  transform: translateY(calc(20px * min(1, var(--max-value) * 10)));
  opacity: calc(1 - min(1, var(--max-value) * 10));
}
.feature-main-fade {
  animation: fade 0.7s ease;
}
.feature-main-chat-fade {
  animation: fadeInUp 0.7s ease;
}

/* ---------- Feature ------------- */

/* ---------- Price Plan ------------- */
.price-plan-div {
  margin-top: 60px;
  height: 100%;
}
.price-plan-title {
  font-size: 32px;
  color: #EA3A62;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #EA3A62;
}
.price-plan-plan-list {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-top: 20px;
}
.price-plan-plan-list-item {
  flex: 1;
  position: relative;
}
.price-plan-item {
  width: 90%;
  height: 380px;
  border-radius: 25px;
  box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.25);
  padding: 20px;
}
.price-plan-item-letter {
  background: #fcdedc;
}
.price-plan-item-gift {
  background: #ffddd5;
  margin-left: 20px;
}
.price-plan-item-img-left {
  position: absolute;
  right: 0px;
  bottom: -45px;
  width: 52%;
  cursor: pointer;
}
.price-plan-item-img-right {
  position: absolute;
  right: -35px;
  bottom: -65px;
  width: 52%;
  cursor: pointer;
}
.price-plan-item-title {
  font-family: 'Megrim';
  font-size: 28px;
  text-align: center;
  letter-spacing: 3px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.price-plan-item-title-letter {
  background: linear-gradient(0deg, #F37F7C, #F37F7C), linear-gradient(90deg, #F37F7C 2.16%, #FE476E 66.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: #F37F7C;
}
.price-plan-item-title-letter-span {
  font-family: 'Yozal';
  background-color: #F37F7C;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 10px;
  color: white;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #fff;
  margin-left: 3px;
  margin-bottom: 10px;
}
.price-plan-item-title-gift {
  background: linear-gradient(0deg, #FF416C, #FF416C), linear-gradient(90deg, #F37F7C 2.16%, #FE476E 66.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: #FF416C;
}
.price-plan-item-title-gift-span {
  font-family: 'Yozal';
  background-color: #FF416C;
  padding: 1px 10px;
  border-radius: 3px;
  font-size: 10px;
  color: white;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #fff;
  margin-left: 5px;
  margin-bottom: 10px;
}
.price-plan-item-price {
  text-align: center;
  font-size: 20px;
  color: #333;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #333;
  margin-top: 15px;
  letter-spacing: 1px;
}
.price-plan-item-list {
  margin-top: 15px;
}
.price-plan-item-list-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  color: #666;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #666;
}
.price-plan-item-letter-check-div {
  font-size: 15px;
  color: #F37F7C;
  margin-right: 8px;
}
.price-plan-item-gift-check-div {
  font-size: 15px;
  color: #fd688a;
  margin-right: 8px;
}
.price-plan-item-list-div-text {
  letter-spacing: 1px;
  font-size: 16px;
}
.price-plan-item-letter-btn {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 40%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #F37F7C;
  height: 40px;
  cursor: pointer;
  font-size: 16px;
}
.price-plan-item-letter-btn:hover {
  opacity: 0.7;
}
.price-plan-item-letter-btn:active {
  opacity: 0.5;
}
.price-plan-item-gift-btn {
  left: 40px;
  bottom: 11px;
}
/* ---------- Price Plan ------------- */

.debug {
  position: fixed;
  top: 0px;
  left: 0px;
}

/* ---------- Scroll Down Animation ------------- */
.mousey {
  width: 1px;
  padding: 6px 12.5px;
  height: 25px;
  border: 2.5px solid #333;
  border-radius: 25px;
  box-sizing: content-box;
}
.scroller {
  width: 2.7px;
  height: 8.5px;
  border-radius: 25%;
  background-color: #FF426C;
  animation-name: scroll;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
.scroll-ps {
  margin-top: 8px;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #333;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(10px); opacity: 0;}
}
@keyframes fade {
  0% { opacity: 0; }
  100% { opacity: 1;}
}
@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(20px);}
  100% { opacity: 1; transform: translateY(0px);}
}




.custom-trigger--0{
  background-color: hsl(0,100%,70%);
}
.custom-trigger--1{
  background-color: hsl(120,100%,70%);
}
.custom-trigger--2{
  background-color: hsl(240,100%,70%);
}
.custom-trigger--3{
  background-color: hsl(360,100%,70%);
}


/* ================= Mobile =================== */
.inner-body-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: auto;
  position: fixed;
  top: 0px;
  left: 0px;
}
.box-div-mobile {
  min-width: 340px;
  max-width: 380px;
  min-height: 520px;
  width: 90vw;
  height: 72vh;
  border-radius: 20px;
  margin-top: 50px;
  background-color: rgba(249,237,231, 0.9);
}
.box-header-mobile {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0px 12px;
  margin: -1px;
  width: calc(100% + 2px);
}
.box-heart-mobile {
  left: 32.5%;
  top: -82px;
  width: 150px;
}
.box-search-div-mobile {
  margin-top: -2px;
  width: 82%;
}
.chatbox-div-mobile {
  min-width: 340px;
  max-width: 380px;
  width: 90vw;
  min-height: 120px;
  max-height: 120px;
  border: 3px solid #F37F7C;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  background-color: rgba(249,237,231, 0.9);
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
}
.chatbox-div-mobile-img {
  width: 34%;
}
.chatbox-text {
  flex: 1;
  height: 70%;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
}
.chatbox-text-brand {
  margin-left: 6px;;
  color: #F37F7C;
  -webkit-text-stroke-width: 1px;
}
.box-mobile-bg-div {
  position: absolute;
  width: 100%;
  height: 94%;
  z-index: -1;
  overflow: hidden;
}
.box-mobile-bg {
  width: 100%;
  position: absolute;
  right: -18%;
  bottom: -19%;
  opacity: 0.1;
}
.box-body-mobile {
  padding: 20px;
  height: 94%;
  display: flex;
  flex-direction: column;
}
.box-body-header-mobile {
  margin-top: 20px;
}
.box-logo-a-mobile {
  width: 55%;
}
.box-body-header-btn-mobile {
  display: flex;
  justify-content: flex-end;
}
.box-nav-btn-mobile {
  font-size: larger;
  margin-right: 5px;
  cursor: pointer;
  color: #F37F7C;
}
.box-header-menu-mobile {
  display: none;
}

.nav-sign-btn-mobile {
  text-align: center;
  margin: 10px 0px;
}

.main-mobile-div {
  color: #F37F7C;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 86%;
  position: absolute;
  top: 70px;
  width: 89%;
  z-index: -1;
}
.main-div-title-mobile {
  margin-top: 0%;
  font-size: 28px;
  -webkit-text-stroke-width: 1.2px;
  -webkit-text-stroke-color: #F37F7C;
}
.main-div-title-mobile b {
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #F37F7C;
}
.main-div-content-mobile {
  font-size: 17px;
}
.main-mobile-main-btn {
  margin-bottom: 15px;
}
.main-action-btn-mobile {
  width: 100%;
  height: 45px;
}

.feature-outer-div-mobile {
  flex-direction: column;
  margin-top: 30px;
  flex: 1;
  justify-content: space-between;
}
.feature-mobile-div-title {
  font-size: 26px;
  color: #EA3A62;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #EA3A62;
}
.feature-mobile-content-div {
  display: flex;
  flex-direction: column;
}
.feature-mobile-content-div-row {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.feature-phone-img-mobile {
  max-height: 340px;
  width: 49%;
}
.feature-2-img-div-mobile {
  top: 14px;
  left: 1.9%;
  width: 62%;
}
.feature-demo-btn-mobile {
  height: 40px;
  font-size: 16px;
}
.feature-3-img-back-mobile {
  margin-top: 10px
}
.feature-5-img-content-mobile {
  margin-top: 10px
}

.feature-mobile-left-div {
  width: 40%;
}
.feature-mobile-right-div {
  width: 100%;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.feature-mobile-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.feature-mobile-list-item-span:active {
  outline: none;
  text-decoration: none;
}
.feature-mobile-list-item-span {
  font-size: 13px;
  color: #333;
  text-align: right;
}
.feature-mobile-list-item-span-active {
  font-size: 16px;
  color: #F37F7C;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #F37F7C;
}
.feature-mobile-list-item-icon {
  width: 18%;
  margin-left: 8px;
  filter: drop-shadow(0px 4px 1px rgba(0, 0, 0, 0.03));
}
.feature-mobile-list-item-icon-active {
  width: 25%;
}
.feature-mobile-price-plan-div-mobile {
  
}
.price-plan-item-mobile {
  width: 100%;
  height: 400px;
  padding-top: 60px;
  margin: 0px;
}
.price-plan-item-img-left-mobile {
  position: absolute;
  right: 0px;
  top: -70px;
  width: 52%;
  cursor: pointer;
}
.price-plan-item-img-right-mobile {
  position: absolute;
  right: 0px;
  top: -100px;
  width: 48%;
  cursor: pointer;
}
.price-plan-item-title-mobile {
  font-size: 24px;
}
.price-plan-item-price-mobile {
  font-size: 18px;
  margin-top: 10px;
}
.price-plan-item-list-mobile {
  margin-top: 10px;
}
.price-plan-item-list-div-text-mobile {
  font-size: 14px;
}
.price-plan-item-letter-btn-mobile {
  width: 88%;
  font-size: 16px;
  margin-bottom: 0px;
}

/* ================= Menu =================== */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 0px;
  height: 0px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 34px;
  width: 34px;
}
.bm-cross-button span {
  top: 8px;
  right: 2px;
}

/* Color/shape of close button cross */
.bm-cross {
  height: 21px !important;
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0px;
}

/* General sidebar styles */
.bm-menu {
  background: #F9EDE7;
  /* border-left: 5px solid #F37F7C;
  border-top: 5px solid #F37F7C;
  border-bottom: 5px solid #F37F7C; */
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.bm-menu a {
  color: #666;
  padding: 15px 0px;
  -webkit-text-stroke-width: 1px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0px;
}